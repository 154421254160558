import React from "react";

interface IFontAwesomeIcon extends React.HtmlHTMLAttributes<HTMLElement> {
  parentIcon: string;
  childIcon: string;
  childStyle?: React.CSSProperties;
}
const CFontAwesomeIcon: React.FC<IFontAwesomeIcon> = ({
  parentIcon,
  childIcon,
  childStyle,
  ...rest
}) => {
  const parentIconStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...rest.style,
  };
  const childIconStyle = {
    fontSize: "0.5em",
    color: "white",
    ...childStyle,
  };
  return (
    <i
      {...rest}
      className={`${parentIcon}`}
      style={{ ...parentIconStyle, position: "relative" }}
    >
      <i
        className={`${childIcon}`}
        style={{ ...childIconStyle, position: "absolute" }}
      ></i>
    </i>
  );
};

export default CFontAwesomeIcon;
